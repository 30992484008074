import { useEffect } from 'react';
import { restClient } from '@/config/rest-client';
import { useAuthStore } from '@/store/useAuthStore';

export function useNewCSRFToken() {
  const [csrfToken, setCsrfToken] = useAuthStore(state => [
    state.csrfToken,
    state.setCsrfToken,
  ]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await restClient().get<{
          csrf_token: string;
        }>('/api/v1/new_csrf_token');
        setCsrfToken(data.csrf_token);
      } catch (error) {
        console.log('useNewCSRFToken', error);
      }
    })();
  }, []);

  return csrfToken;
}
