export const STORAGE_KEYS = {
  THEME: 'theme',
  AUTH_STATUS: '_tapaz_authStatus',
  BOOKMARKS: '_tapaz_bookmarks',
  LAST_SCROLL_POSITION: '_tapaz_last_scroll_position',
  PREVIOUS_BANNERS_HEIGHT: '_tapaz_previous_banners_height',
  PREMIUM_ADS: '_tapaz_premium_ads',
  PREMIUM_ADS_NEXT_CURSOR: '_tapaz_premium_ads_next_cursor',
  CATALOGUE_SCROLL_LEFT: '_tapaz_catalogue_scroll_left',
  NEED_TO_RESTORE_SCROLL_POSITION: '_tapaz_need_to_restore_scroll_position',
  AUTH_STORAGE: '_tapaz_auth_storage',
  BRIDGE: '_tapaz_bridge_storage',
};
