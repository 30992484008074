import type { AppProps } from 'next/app';
import { I18nextProvider } from 'react-i18next';
import { GlobalStyles } from '@/styles/globalStyles';
import i18n from '@/i18n';
import Script from 'next/script';
import Router from 'next/router';
import { BlockerDetector } from '@/components/Banners/BlockerDetector';
import NoSsr from '@/components/NoSsr/NoSsr';
import { BannersStyles } from '@/components/Banners/Banners.styles';
import { ApolloProvider } from '@apollo/client';
import { createApolloClient } from '@/config/apollo-client';
import { loadDevMessages } from '@apollo/client/dev';
import { isProduction } from '@/utils/helpers';
import { useEffect } from 'react';
import { initializeGoogleTagManager } from '@/utils/googleTagManager';
import { CONFIG } from '@/constants/config';
import { PubNubInit } from '@/components/PubNubInit/PubNubInit';
import { useNewCSRFToken } from '@/hooks/useNewCSRFToken';
import { useBodyClasses } from '@/hooks/useBodyClasses';
import { AuthCheck } from '@/components/PubNubInit/AuthCheck';
import { BridgeProvider } from '@/bridge/BridgeProvider';
import { ThemeProviderWrapper } from '@/hooks/theme/wrapper';
import { noTopInsert } from '@/bridge/utils/noTopInsert';

if (!isProduction()) {
  loadDevMessages();
}

function App({ Component, pageProps }: AppProps<{ isSSRWebView: boolean }>) {
  useBodyClasses();
  const CSRFToken = useNewCSRFToken();

  useEffect(() => {
    initializeGoogleTagManager(CONFIG.gtmContainerId);

    if (pageProps.isSSRWebView) {
      noTopInsert(); // if this is the first page
      Router.events.on('beforeHistoryChange', noTopInsert);
    }
  }, []);

  if (!CSRFToken) {
    return null;
  }

  return (
    <>
      <Script src="/scripts/adriver.core.2.js" />

      <BridgeProvider isWebView={pageProps.isSSRWebView}>
        <ThemeProviderWrapper isWebView={pageProps.isSSRWebView}>
          <GlobalStyles />
          <BannersStyles />
          <I18nextProvider i18n={i18n}>
            <ApolloProvider
              client={createApolloClient(i18n.language, CSRFToken)}
            >
              <AuthCheck />
              <PubNubInit />
              <NoSsr>
                <BlockerDetector />
              </NoSsr>

              <Component {...pageProps} />
            </ApolloProvider>
          </I18nextProvider>
        </ThemeProviderWrapper>
      </BridgeProvider>
    </>
  );
}

export default App;
