import i18n from '@/i18n';
import { OPERATOR_CODES } from '@/constants/common';
import { ROUTES } from '@/constants/routes';
import { CONFIG } from '@/constants/config';
import { GetServerSidePropsContext } from 'next';
import { HostSum, OpenLinkInNewWindowParams } from '@/utils/helpers.types';
import { az, ru } from 'date-fns/locale';
import { format } from 'date-fns';

export const setSuffix = (
  i18next: typeof i18n,
  val: string,
  prefix: string
) => {
  const count = parseInt(val, 10);
  const lang = i18next.language;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  const suffix = i18next.services.pluralResolver.getSuffix(
    lang,
    count
  ) as string;

  return `${prefix}${suffix || ''}`;
};

export const getReversedLangDefinition = (lang: string) => {
  switch (lang) {
    case 'ru':
      return {
        short: 'az',
        long: 'Azərbaycanca',
      };
    default:
      return {
        short: 'ru',
        long: 'Русский язык',
      };
  }
};

export const hostnameWithoutLang = (host: string) => {
  const lang = host.split('.')[0];
  let pureHost = host;
  if (CONFIG.languages.includes(lang)) {
    pureHost = host.split('.').splice(1).join('.');
  }
  return pureHost;
};

export const makeUrlWithLang = ({
  protocol,
  host,
  path,
  lang,
}: {
  protocol: string;
  host: string;
  path: string;
  lang?: string;
}) => {
  const defaultLang = 'az';
  const language = !lang || lang === defaultLang ? '' : `${lang}.`;
  return `${protocol}://${language}${hostnameWithoutLang(host)}${path}`;
};

export const makeHrefForPhone = (phone: string) => {
  const digitsOnly = phone.replace(/\D/g, '');
  const withoutLeadingZero = digitsOnly.replace(/^0+/, '');
  return `tel:+994${withoutLeadingZero}`;
};

export const sleep = (ms: number) =>
  // eslint-disable-next-line no-promise-executor-return
  new Promise(resolve => setTimeout(resolve, ms));

export function formatPhoneNumber(
  input?: string | ReadonlyArray<string> | number
) {
  if (typeof input !== 'string') {
    return '';
  }

  const digitsOnly = input.trim().replace(/\D/g, '');
  const match = digitsOnly.match(/^(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})(\d*)$/);

  if (!match) {
    return input.slice(0, -1);
  }

  const [, part1, part2, part3, part4] = match;

  const formattedParts = [
    part1 ? `(${part1}` : '',
    part2 ? `) ${part2}` : '',
    part3 ? `-${part3}` : '',
    part4 ? `-${part4}` : '',
  ];

  return formattedParts.join('');
}

export const toBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result as string);
    };

    reader.onerror = () => {
      reject(new Error('Failed to convert the file to base64.'));
    };

    reader.readAsDataURL(file);
  });
};

export const isProduction = () =>
  process.env.VERCEL_ENV === 'production' || CONFIG.isProduction === 'true';

export const formatNumber = (num?: number | null, decimalPlaces = 0) => {
  if (num === undefined || num === null) return '';

  const options = {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  };

  const formattedNumber = num.toLocaleString('ru-RU', options);

  return formattedNumber.replace(/\u00A0/g, ' ');
};

export const extractOperatorCodeFromPhone = (phone: string) => {
  const pattern = /\((\d{3})/;
  const match = pattern.exec(phone);

  if (match && match.length > 1) {
    return match[1];
  }

  return null;
};

export const checkCodeOperatorValidity = (code: string | null) => {
  if (code === null) {
    return false;
  }

  return OPERATOR_CODES.includes(code);
};

export function getAdPagePath(slug?: string | string[]) {
  const slugStr = Array.isArray(slug) ? slug?.join('/') : `/${slug}`;
  return `${ROUTES.ad}/${slugStr}`;
}

export const onlyNumbers = (value: string | null | undefined) => {
  return value ? value.replace(/[^0-9]/g, '') : '';
};

export const addSpacesBetweenNumbers = (value: string) => {
  return onlyNumbers(value).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const getDataStatAttr = ({
  dataStat,
  condition = true,
}: {
  dataStat?: string;
  condition?: boolean;
}) => {
  return condition && dataStat ? { 'data-stat': dataStat } : {};
};

export function encodeString(string: string) {
  return Buffer.from(string).toString('base64');
}

export const getLoginUrlWithReturn = (
  onSuccessReturnUrl: string,
  backUrl?: string
) => {
  if (!CONFIG.protocol || !CONFIG.host || typeof window === 'undefined') {
    return '';
  }

  const returnTo = encodeString(window.location.origin + onSuccessReturnUrl);

  const backTo = encodeString(
    window.location.origin + (backUrl ?? ROUTES.home)
  );

  return makeUrlWithLang({
    protocol: CONFIG.protocol,
    host: CONFIG.host,
    path: `${ROUTES.login}?return_to=${returnTo}&back_to=${backTo}`,
    lang: i18n.language,
  });
};

export const getUrlBasedOnAuth = (
  isAuthenticated: boolean,
  onSuccessReturnUrl: string,
  backUrl: string
) => {
  return isAuthenticated
    ? onSuccessReturnUrl
    : getLoginUrlWithReturn(onSuccessReturnUrl, backUrl);
};

export const hostname = (lang: string, host: string) => {
  return lang === 'az' ? host : `${lang}.${host}`;
};

export const normalizePath = (asPath?: string) => {
  if (!asPath) return '';

  try {
    const urlObject = new URL(asPath);
    urlObject.search = '';
    return urlObject.toString();
  } catch (error) {
    return asPath;
  }
};

export const getHostFromReq = (req: GetServerSidePropsContext['req']) => {
  const { headers } = req;
  const reqHost =
    headers.host || headers['x-real-host'] || headers['x-forwarded-host'];

  const lang = reqHost?.includes('ru.') ? 'ru' : 'az';

  return (
    reqHost?.includes('react.tap.az') && CONFIG.host
      ? hostname(lang, CONFIG.host)
      : reqHost
  ) as string;
};

export const makeHostSum = ({
  asPath = '/',
  req,
}: {
  asPath?: string;
  req?: GetServerSidePropsContext['req'];
}): HostSum => {
  const host = req ? getHostFromReq(req) : window.location.host;
  const protocol = req
    ? req.headers['x-forwarded-proto'] || 'https'
    : window.location.protocol.slice(0, -1);

  const currentUrl = `${protocol}://${host}${asPath}`;

  return {
    currentUrl,
    protocol: Array.isArray(protocol) ? protocol[0] : protocol,
    host,
    path: normalizePath(asPath),
  };
};

export function removeLangFromUrl(url: string): string {
  const langRegex = /^(https?:\/\/)[a-z]{2}\./i;

  return url.replace(langRegex, '$1');
}

export function addLangToUrl(baseUrl: string, lang: string): string {
  if (lang === 'az') return baseUrl;

  const domain = baseUrl
    .replace(/\/$/, '')
    .replace(/^(https?:\/\/)([^/]+)(\/.*)?$/, '$2');

  return `${baseUrl.replace(domain, `${lang}.${domain}`)}`;
}

export function isJestRunning() {
  return process.env.JEST_WORKER_ID !== undefined;
}

export function isCI() {
  return !!process.env.CI;
}

export function sortByObjectProperty<T extends Record<string, unknown>>(
  arr: T[],
  property: keyof T,
  reverse = false
): T[] {
  const copy = arr.slice();

  return copy.sort((a, b) => {
    const compareResult = a[property] < b[property] ? 1 : -1;
    return reverse ? -compareResult : compareResult;
  });
}

export function transformCapitalAzI(str: string) {
  return str.replaceAll('İ', 'I');
}

export function cutSentenceFor2Parts(sentence: string, cutSubstring: string) {
  const lowerCasedSentence = transformCapitalAzI(sentence).toLowerCase();
  const lowerCasedCutSubstring =
    transformCapitalAzI(cutSubstring).toLowerCase();
  const index = lowerCasedSentence.indexOf(lowerCasedCutSubstring);

  const firstPart = sentence.slice(0, index + cutSubstring.length);
  const secondPart = sentence.slice(index + cutSubstring.length);
  return [firstPart, secondPart];
}

export function isProductionClient() {
  const href = typeof window !== 'undefined' && window.location.href;
  const isProductionHref =
    typeof href === 'string' &&
    (href?.startsWith('https://tap.az') ||
      href?.startsWith('https://ru.tap.az'));

  return { href, isProductionHref };
}

export function isEndsWithNumbers(str: string) {
  const regex = /\d+$/;
  return regex.test(str);
}

export function cleanPhoneNumber(phoneNumber: string) {
  return phoneNumber.replace(/[()\s-]/g, '');
}

export function startCall(phoneNumber: string) {
  document.location.href = `tel:${cleanPhoneNumber(phoneNumber)}`;
}

export function removeBaseUrl(url: string) {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.pathname + parsedUrl.search + parsedUrl.hash;
  } catch (error) {
    return url;
  }
}

export function startsWithHttp(url: string) {
  const regex = /^https?:\/\//;
  return regex.test(url);
}

export function getBaseUrl(fullUrl: string): string {
  try {
    const url = new URL(fullUrl);
    const baseUrlWithoutPort = `${url.protocol}//${url.hostname}`;
    return url.port ? `${baseUrlWithoutPort}:${url.port}` : baseUrlWithoutPort;
  } catch (error) {
    return '';
  }
}

export function openLinkInNewWindow({
  url,
  width = 600,
  height = 800,
  top = 0,
  left = 0,
}: OpenLinkInNewWindowParams) {
  const newWindow = window.open(
    '',
    '_blank',
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
  );
  if (newWindow) {
    newWindow.location.href = url;
  }
}

export const removeWhitespaces = (str: string) => str.replace(/[()\s-]/g, '');

export function getLocale() {
  if (typeof window !== 'undefined') {
    const url = window.location.href;
    const regex = /^https?:\/\/(ru)\./;
    return regex.test(url) ? ru : az;
  }
}

export function formatDate(date?: Date | string, formatStr?: string) {
  return format(new Date(date ?? ''), formatStr ?? 'dd MMMM HH:mm', {
    locale: getLocale(),
  });
}

export function formatPhoneNumberFromBE(phoneNumber?: string): string {
  if (!phoneNumber) {
    return '';
  }
  const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return `${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
  }
  return phoneNumber;
}

export function calculatePercentageOff(number: number, percentage: number) {
  return number - (number * percentage) / 100;
}

export const replaceSpaces = (str?: string) => str?.replaceAll(/\s/g, '');

export function generateRandomNumber(max = 10_000) {
  return Math.floor(Math.random() * max) + 1;
}
