import { useAuth } from '@/hooks/useAuth';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

export const AuthCheck = () => {
  const router = useRouter();
  const getBaseUser = useAuth();

  useEffect(() => {
    getBaseUser();
  }, [router.asPath]);

  return null;
};
