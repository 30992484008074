import { gql } from '@apollo/client';
import { ServiceName } from '@/api/types/ads.types';

export const AD_BASE_FIELDS = gql`
  fragment AdBaseFields on Ad {
    id
    title
    price
    updatedAt
    region
    path
    flags
    legacyResourceId
    shop {
      id
    }
    photo {
      url
    }
  }
`;

export const GET_ADS = (adKind: ServiceName) => {
  const name = `GetAds_${adKind}`;
  return gql`
    ${AD_BASE_FIELDS}
    query ${name} (
      $adKind: AdKindEnum
      $orderType: AdOrderEnum
      $keywords: String
      $first: Int
      $after: String
      $filters: FilterAttributes
    ) {
      ads(
        adKind: $adKind
        first: $first
        after: $after
        source: MOBILE
        orderType: $orderType
        keywords: $keywords
        filters: $filters   
      ) {
        nodes {
          ...AdBaseFields
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
    }
  `;
};

export const GET_ADS_FOR_BOOKMARKS = gql`
  ${AD_BASE_FIELDS}
  query GetAdsForBookmarks($ids: [ID!]) {
    ads(ids: $ids, source: DESKTOP) {
      nodes {
        ...AdBaseFields
      }
    }
  }
`;

export const GET_AD_DETAILS = gql`
  ${AD_BASE_FIELDS}
  query GetAd($legacyId: ID!) {
    adDetails(legacyId: $legacyId, source: MOBILE) {
      birkartBanner {
        hasCard
        hasModal
        hasKredit
      }
      id
      title
      body
      price
      updatedAt
      region
      path
      flags
      relatedAds {
        ...AdBaseFields
      }
      shop {
        id
        ads {
          ...AdBaseFields
        }
        logo
        name
        uri
        workingDays
        workingHours
        description
      }
      photos {
        url
      }
      bookmarksCount
      callsCount
      contact {
        id
        name
        logo {
          url
        }
        phones
        type
      }
      hits
      legacyResourceId
      path
      properties {
        name
        value
        link
      }
      status
      user {
        id
      }
      reportReasons {
        id
        name
      }
      expiresAt
      chatPath
      vippedUntil
      featuredUntil
      bumpServices {
        price
        period
        previousPrice
      }
      vipServices {
        price
        period
        previousPrice
      }
      featuredServices {
        price
        period
        previousPrice
      }
      rejectReasons {
        id
        name
        template
      }
      isCallsAvailable
      isChatAvailable
      editLockedUntil
      isExpiredManually
    }
  }
`;
